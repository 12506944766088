// i18next-extract-mark-ns-start contacts-page
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import React from 'react';
import PartnersForm from '../components/PartnersForm/PartnersForm';
import { Seo } from '../components/Seo';
import Layout from '../layouts/layout';

import * as classes from './partners.module.scss';

const PartnersPage = props => {
    return <Layout location={props.location}>
        <div className="container">
            <div className={clsx(classes.Partners, 'row')}>
                <div className={clsx('col-md-6', classes.Claim)}>
                    <h1 className={classes.Title}>
                        <Trans>Become an AIDA partner</Trans>
                    </h1>
                    <h2 className={classes.Subtitle}>
                        <Trans i18nKey="partners-page:cta1">
                            <p>Join other successful companies that grow their businesses with our industry-leading
                               technology.</p>
                            <p>Grow your client value and satisfaction by accessing our partner specific tools and
                               opportunities.</p>
                        </Trans>
                    </h2>
                </div>
                <div className={clsx('col-md-6', classes.FormContainer)}>
                    <PartnersForm />
                </div>
            </div>
        </div>
    </Layout>;
};

PartnersPage.propTypes = {};

export default PartnersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "partners-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'partners-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Partners';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
